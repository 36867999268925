import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
    <li>
        <a href="https://freshbatch.io">
            <i className="fas fa-globe-americas fa-lg"></i>
            View site &raquo;
        </a>
    </li>
    </ul>
    <p>{`As a part-time gig I've been helping a new startup, FreshBatch, whose goal is to provide an easy-to-use meal planning and nutrition tracking application. I've helped by building a design system and component library as well as assisting with application development.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/freshbatch-planner.png",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/work/freshbatch-styles.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      